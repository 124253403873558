import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})

export class BadgesComponent implements OnInit {
  public badgesList: Array<any>;
  public badge: any;
  public show = false;
  public buttonName: any = 'Show';
  getid: string;

  searchText = '';
  constructor(private formBuilder: FormBuilder, public baseService: BaseService,
    public router: Router) { }
  addBadges: FormGroup;
  addBadge: AddBadgess;
  id: any;
  showDeletedMessage: boolean;
  showSavedMessage: boolean;

  ngOnInit() {
    this.loadBadges();
    this.addBadges = this.formBuilder.group({
      id: [null],
      index: [{ value: null, disabled: true }],
      name: [null, Validators.required],
      points: [null, Validators.required]
    });
  }
  get f() { return this.addBadges.controls; }

  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Hide';
    } else {
      this.buttonName = 'Show';
    }
  }


  onSubmit(form: NgForm) {
    if (this.addBadges.invalid) {
      return;
    }
    const getid = this.addBadges.value.id;
    this.addBadge = {
      name: this.addBadges.value.name,
      points: this.addBadges.value.points,
    };
    if (getid == null) {
      //  debugger
      this.baseService.post(Constants.BADGES, this.addBadge).subscribe(response => {
        this.addBadges.reset();
        this.loadBadges();
      });
    } else {
      this.baseService.put(Constants.BADGES + '/' + getid, this.addBadge).subscribe(response => {

        this.addBadges.reset();
        this.loadBadges();
      });
    }

    this.showSavedMessage = true;
    setTimeout(() => this.showSavedMessage = false, 3000);
  }

  public loadBadges() {
    this.baseService.get(Constants.BADGES).subscribe(response => {
      this.badgesList = response;
    });
  }

  deleteBadge(id) {
    if (confirm('Are you sure to delete this record ?')) {
      this.baseService.delete(Constants.BADGES + '/' + id, '').subscribe(response => {
        this.loadBadges();
      });
      this.showDeletedMessage = true;
      setTimeout(() => this.showDeletedMessage = false, 3000);
    }

  }



  updateProfile(badge, i) {
    this.addBadges.setValue({
      index: i,
      id: badge.id,
      name: badge.name,
      points: badge.points
    });
    this.toggle();
  }

  filterCondition(badge) {
    return badge.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
  }
}


export interface AddBadgess {
  name: string;
  points: number;
}
