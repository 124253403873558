import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {

  public skillList: Array<any>;
  public skill: any;
  public show = false;
  public buttonName: any = 'Show';
  getid: string;

  searchText = '';
  constructor(
    private formBuilder: FormBuilder,
    public baseService: BaseService,
    public router: Router) { }

  addskill: FormGroup;
  addSkills: Addskills;
  id: any;
  showDeletedMessage: boolean;
  showSavedMessage: boolean;


  ngOnInit() {
    this.loadSkills();
    this.addskill = this.formBuilder.group({
      id: [null],
      index: [{ value: null, disabled: true }],
      name: [null, Validators.required],
      created_date: Date.now()
    });
  }
  get f() { return this.addskill.controls; }

  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Hide';
    } else {
      this.buttonName = 'Show';
    }
  }


  onSubmit(form: NgForm) {
    if (this.addskill.invalid) {
      return;
    }
    const getid = this.addskill.value.id;
    this.addSkills = {
      name: this.addskill.value.name,
      created_date: this.addskill.value.created_date
    };
    if (getid == null) {
      //  debugger
      this.baseService.post(Constants.SKILLS, this.addSkills).subscribe(response => {
        this.addskill.reset();
        this.loadSkills();
      });
    } else {
      this.baseService.put(Constants.SKILLS + '/' + getid, this.addSkills).subscribe(response => {

        this.addskill.reset();
        this.loadSkills();
      });
    }

    this.showSavedMessage = true;
    setTimeout(() => this.showSavedMessage = false, 3000);
  }

  public loadSkills() {
    this.baseService.get(Constants.SKILLS).subscribe(response => {
      this.skillList = response;
    });
  }

  deleteSkill(id) {
    if (confirm('Are you sure to delete this record ?')) {
      this.baseService.delete(Constants.SKILLS + '/' + id, '').subscribe(response => {
        this.loadSkills();
      });
      this.showDeletedMessage = true;
      setTimeout(() => this.showDeletedMessage = false, 3000);
    }

  }

  updateProfile(skill, i) {
    this.addskill.setValue({
      index: i,
      id: skill.id,
      name: skill.name,
      created_date: Date.now()
    });
    this.toggle();
  }

  filterCondition(skill) {
    return skill.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
  }

}

export interface Addskills {
  name: string;
  created_date: Date;
}

