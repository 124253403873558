import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormGroupDirective,
  FormControl,
  NgForm,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { Constants } from "../shared/constants";
import { BaseService } from "../core/base.service";
import { AdalService } from "adal-angular4";
import { Router } from "@angular/router";
import * as moment from "moment";
import { add } from "lodash";
import { DateRangePickerComponent } from "ngx-daterange";
import {
  IDateRange,
  IDateRangePickerOptions,
} from "./../../../node_modules/ngx-daterange/interfaces";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  public projects: Array<any>;
  public pointsList: Array<any>;
  form: FormGroup;
  public points: number = 0;
  public bonus: number = 0;
  public value;
  searchText = "";
  public year: any = new Date().getFullYear();
  currentyear: any;
  selectedyear: any;
  dateRangePicker: DateRangePickerComponent;
  projectName: any;

  firstFieldEmittedValue: IDateRange;
  firstFieldOptions: IDateRangePickerOptions = {
    autoApply: false,
    format: "DD/MM/YYYY",
    icons: "material",
    minDate: moment().subtract(2, "years"),
    maxDate: moment(),
    preDefinedRanges: [
      {
        name: "Last Week",
        value: {
          start: moment().subtract(1, "week").startOf("week"),
          end: moment().subtract(1, "week").endOf("week"),
        },
      },
      {
        name: "Two Weeks Ago",
        value: {
          start: moment().subtract(2, "week").startOf("week"),
          end: moment().subtract(2, "week").endOf("week"),
        },
      },
    ],
    startingFromDate: moment().subtract(1, "month"),
    startingToDate: moment(),
    validators: null,
  };

  secondFieldOptions: IDateRangePickerOptions = {
    autoApply: false,
    format: "DD/MM/YYYY",
    icons: "font-awesome",
    minDate: moment().subtract(10, "years"),
    maxDate: moment(),
    preDefinedRanges: [
      {
        name: "Last Week",
        value: {
          start: moment().subtract(1, "week").startOf("week"),
          end: moment().subtract(1, "week").endOf("week"),
        },
      },
      {
        name: "Two Weeks Ago",
        value: {
          start: moment().subtract(2, "week").startOf("week"),
          end: moment().subtract(2, "week").endOf("week"),
        },
      },
    ],
  };

  rightFieldOptions: IDateRangePickerOptions = {
    format: "DD/MM/YYYY",
    icons: "material",
    minDate: moment().subtract(2, "years"),
    maxDate: moment(),
    position: "right",
  };

  constructor(
    public baseService: BaseService,
    public router: Router,
    private adalService: AdalService
  ) {
    // this.add(this.data);
  }

  get authenticated(): boolean {
    return this.adalService.userInfo.authenticated;
  }
  public loadPoints() {
    var profilename = this.adalService.userInfo.profile.name;
    this.baseService
      .get(Constants.GETALLMYASSIGNEDPOINTS)
      .subscribe((response) => {
        this.pointsList = response;
        this.value = response;
        for (let j = 0; j < this.value.length; j++) {
          // console.log(this.value[j].points);
          if (this.value[j].points != "") {
            this.points += this.value[j].points;
          }
          // console.log(this.value[j].bonus_points);
          if (this.value[j].bonus_points != "") {
            this.bonus += this.value[j].bonus_points;
            // console.log(this.bonus);
          }
        }
      });
  }

  ngOnInit() {
    // console.log(this.adalService.userInfo);
    this.getProjectList();
    this.loadPoints();

    this.form = new FormGroup({
      secondDateRange: new FormControl(null),
      projectName: new FormControl(null),
    });
  }

  getValue(searchValue: string): void {
  //  console.log(searchValue);
  }

  onSubmit(form: NgForm) {
    var str = this.form.get("secondDateRange").value;
    var splitted = str.split(" - ", 2);
  //  console.log(splitted[0] + " " + splitted[1]);
    var StartDateVar = moment(splitted[0], "DD/MM/YYYY");

    var EndDateVar = moment(splitted[1], "DD/MM/YYYY");
    var sDate = StartDateVar.format("YYYY-MM-DD") + "T00:00:00.000Z";
    var eDate = EndDateVar.format("YYYY-MM-DD") + "T24:00:00.000Z";

    this.points = 0;
    this.bonus = 0;
    var profilename = this.adalService.userInfo.profile.name;
    var projectname = encodeURIComponent(this.form.get("projectName").value);
    // console.log(encodeURIComponent(projectname))
    
    this.baseService
      .get(Constants.GETALLMYASSIGNEDPOINTS + projectname)
      .subscribe((response) => {
        this.pointsList = response.filter(
          (m) =>
            new Date(m.assigned_date) >= new Date(sDate) &&
            new Date(m.assigned_date) <= new Date(eDate)
        );
        this.pointsList = this.pointsList.filter(
          (proj) => proj.project_name === this.form.get("projectName").value
        );
        this.value = this.pointsList;

        for (let j = 0; j < this.value.length; j++) {
        //  console.log(this.value[j].points);
          if (this.value[j].points != "") {
            this.points += this.value[j].points;
          }
          if (this.value[j].bonus_points != "") {
            this.bonus += this.value[j].bonus_points;
          //  console.log(this.bonus);
          }
        }

      //  console.log(Number(this.points) + Number(this.bonus));
      });
  }

  public getProjectList() {
    this.baseService.get(Constants.GETPROJECTS).subscribe((response) => {
      this.projects = response;
    //  console.log(response);
    });
  }
}
