import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';
import { DataSharingService } from 'src/app/core/data-sharing.service';
import { User } from '../models/user';

@Component({
  selector: 'app-top-employees',
  templateUrl: './top-employees.component.html',
  styleUrls: ['./top-employees.component.scss']
})
export class TopEmployeesComponent implements OnInit, OnDestroy {

  public topEmployees: Array<any>;
  public topEmployeesSub: any;
  empScore

  constructor(public baseService: BaseService,
    public dataSharingService: DataSharingService) { }

  ngOnInit() {
    this.topEmployeesSub = this.dataSharingService.topEmployees.subscribe((topEmp: Array<User>) => {
      this.topEmployees = topEmp;

      
    });
  }

  public getTopEmployees() {
    this.baseService.get(Constants.TOPUSERS).subscribe(response => {
      this.topEmployees = response;
// console.log(this.topEmployees)
    });
  }

  ngOnDestroy() {
    this.dataSharingService.isPointsAssigned(false);
    setTimeout(() => {
      this.topEmployeesSub.unsubscribe();
    }, 300);
  }



}
