import { Component, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { BaseService } from 'src/app/core/base.service';
import { AdalService } from 'adal-angular4';
import { ModalDirective } from 'angular-bootstrap-md';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('frame') alert: ModalDirective;

  public data: any;
  public flag: any = false;
  public filterData: any;
  public email: Array<any>;

  constructor(public baseService: BaseService,private adalService: AdalService) { }

  ngOnInit() {
    this.baseService.get(Constants.GETALLUSERS).subscribe(response => {
      this.data = response;
      let email = this.adalService.userInfo.userName;
      // console.log(email)
      // console.log(this.data)
      if (this.data) {
        this.filterData = this.data.filter(
          element => element.email === email
        );
        if(this.authenticated){
          this.filterData[0].can_assign ? this.flag = true : this.flag = false;
          // if(this.filterData[0].can_assign){
          //   this.flag = true;
          // } else {
          //   this.flag = false;
          // }
        }
        // if (this.filterData.length && Constants.ASSIGNARRAY.includes(this.filterData[0].role.toLowerCase())) {
        //   this.flag = true;
        // } else {
        //   this.flag = false;
        // }
      }
    });
    this.adalService.handleWindowCallback();

    //console.log(this.adalService.userInfo.userName)
   // console.log(this.adalService.userInfo);
  }

  login() {
    this.adalService.login();
  }

  logout() {
    this.adalService.logOut();
  }

  get superAdmin(): boolean{
    if (this.adalService.userInfo.userName == 'sandeep.mane@arrkgroup.com' && 'siddesh.hardikar@arrkgroup.com'){
      return true;
    }
  }

  get authenticated(): boolean {
    return this.adalService.userInfo.authenticated;
  }



}
