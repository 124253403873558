import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';
import { AdalService } from 'adal-angular4';
import { ToastrService } from 'ngx-toastr';
import { DataSharingService } from '../core/data-sharing.service';
import * as moment from 'moment';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  public employee: any;
  public user: any;
  public points: any;
  public year: string = (new Date().getFullYear()).toString();
  public totalpoints: any;
  public assignedby: any;
  public updateUser: any;
  public assignPoints: any;
  

  public updateAssignerBK: any;
  public userID: any;
  public assigninguserID: any;


  constructor(public baseService: BaseService,
    private adalService: AdalService,
    private toastr: ToastrService,
    public dataSharingService: DataSharingService) { }

  ngOnInit() {
    this.employee = JSON.parse(localStorage.getItem('employee'));
    this.getUserDetails();
  //  console.log(this)

    this.getUserComments();
    this.assignedby = this.adalService.userInfo.profile.name;
  //  console.log(this.assignedby)

  }

  showSuccess() {
    this.toastr.success('Success', 'You have successfully assigned points', {
      positionClass: 'toast-bottom-right'
    });
  }

  getYear(){
    return parseInt(this.year) + 1
  }

  deletePoints(id) {
    if (confirm('Are you sure to delete this record ?')) {
      // console.log(this.user.total_points)
      // console.log(this.points[0].points)
      // console.log(this.points[0].bonus_points)
      // console.log(this.user.total_points - this.points[0].points - this.points[0].bonus_points)
      // console.log(this)
      this.baseService.delete(Constants.POSTPOINTS + '/' + id, '').subscribe(response => {
        this.updateUser = { 'total_points': this.user.total_points - this.points[0].points - Number(this.points[0].bonus_points) };
        // console.log(this.updateUser)
        this.baseService.patch(Constants.GETSINGLEUSER + this.user.id, this.updateUser).subscribe(res => {
          this.dataSharingService.isPointsAssigned(true);
        });

        this.baseService.get(Constants.GETFINDONEEMAIL + this.adalService.userInfo.profile.unique_name).subscribe(response => {
          this.assigninguserID = response[0].id;
          this.updateAssignerBK = { 'bonus_kitty': response[0].bonus_kitty + this.points[0].bonus_points };
          // console.log(this.updateAssignerBK)
          // console.log(response)
          this.baseService.patch(Constants.GETSINGLEUSER + "/" + this.assigninguserID, this.updateAssignerBK).subscribe(response => {
          //  console.log(response)
            if(response){
              this.getUserDetails();
              this.showSuccess();
              window.location.reload();
            }
          })
        });

      });
    }

  }

  public getUserDetails() {
 //  console.log(this.employee.id)
    this.baseService.get(Constants.GETSINGLEUSER + this.employee.id).subscribe(response => {
      this.user = response;
      // employee.points.reduce((sum, item) => sum + item.points, 0);
      // console.log(this.user)
      // console.log(this.employee.total_points);
    });
  }

  public getUserComments() {
    this.baseService.get(Constants.GETUSERCOMMENTS + this.employee.employee_no).subscribe(response => {
      this.points = response;
      //console.log(this.points.assigned_by)
    });
  }

}
