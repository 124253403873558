import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accomplishment-new',
  templateUrl: './accomplishment-new.component.html',
  styleUrls: ['./accomplishment-new.component.scss']
})
export class AccomplishmentNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
