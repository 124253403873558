import { Component, OnInit, Pipe } from '@angular/core';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';
import { Router } from '@angular/router';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { DataSharingService } from 'src/app/core/data-sharing.service';
import { User, Point } from '../models/user';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  public optionsSelect: Array<any>;
  public employees: Array<User>;
  public points: Array<Point>;
  public noData: string;
  public pointsAssignedSub: any;
  public year: any = new Date().getFullYear();
  currentyear: any;
  selectedyear: any;
  isCollapsed = true;
  constructor(public baseService: BaseService,
    public router: Router,
    public dataSharingService: DataSharingService) { }

  ngOnInit() {
    this.loadList();
    this.currentyear = new Date().getFullYear();
  //  console.log(this.currentyear)
   // console.log(this.currentyear + '-03-31')
   // console.log(new Date())
    const dateIsAfter = moment(new Date()).isAfter(moment(this.currentyear + '-03-31'));

    if (dateIsAfter) {
      this.year = new Date().getFullYear();
    } else {
      //  this.year--
      this.selectedyear = moment(new Date().getFullYear()) ;
      // console.log(this.selectedyear)
      this.year = (this.selectedyear._i - 1).toString();

    //  console.log(this.year)
    }

    this.pointsAssignedSub = this.dataSharingService.pointsAssigned.subscribe((isPointsAssigned: boolean) => {
      if (isPointsAssigned) {
        this.refreshUsersList();
      }
    });
  }

  public refreshUsersList() {
    this.loadList();
    // TODO: Refresh user list from here on assignment of points.
  }

  public loadList() {
   // console.log("1");
    this.baseService.get(Constants.USERS).subscribe(response => {
      this.employees = response;
    // this.employees = response.filter(
    //   element => element.active !== false
    //   );
    // console.log('1',this.employees)
      const nextYear = parseInt(this.year, 10) + 1;
      this.employees.forEach(element => {
        element.points = element.points.filter(point => {
        //  console.log(this.year + '-04-01T05:00:00.000Z', nextYear + '-03-31T05:00:00.000Z')
          return moment(point.assigned_date).isBetween(this.year + '-04-01T05:00:00.000Z', nextYear + '-03-31T05:00:00.000Z');
        });
        element.total_points = 0;

        element.points.forEach(point => {
         if (moment(point.assigned_date).isBetween(this.year + '-04-01T05:00:00.000Z', nextYear + '-03-31T05:00:00.000Z')) {
           element.total_points = +element.total_points + +point.points + +point.bonus_points;
          //  console.log("if")
         } else {
          //  console.log("else")
           return false;
         }
        });
      });
      this.sortEmployeeList();
    });
  }

  public sortEmployeeList() {
  //  console.log(this.employees)
//    this.employees = this.employees.filter(emp => emp.total_points > 0);

    this.employees = _.orderBy(this.employees, ['total_points'], ['desc']);
   // console.log(this.employees)

    this.dataSharingService.isPointsUpdated(this.employees.slice(0, 3));
   // console.log(this.employees)
  }

  public filterUsers(searchText: string) {
    if (searchText.trim().length > 2 || searchText.length === 0) {
     // console.log(this.employees)
      this.baseService.get(Constants.SEARCHUSERS + searchText).subscribe(response => {
        if (response.length > 0) {
          this.employees = response;
          this.noData = '';
        } else {
          this.employees = [];
          this.noData = 'No records found!';
        }
      });
    }
  }

  public showBsCollapse(event, emp) {
    localStorage.setItem('employee', JSON.stringify(emp));
    const allTopTHreeComments = document.getElementsByClassName('top-three-comments');
    for (let i = 0; i < allTopTHreeComments.length; i++) {
      allTopTHreeComments[i].classList.remove('show');
    }
  }

  public showDetails(emp) {
   // console.log(this.router.navigateByUrl)
    localStorage.setItem('employee', JSON.stringify(emp));
    this.router.navigateByUrl('/user/details');
    // this.router.navigate(['/user/details', id]
  }

  ngOnDestroy() {
    this.dataSharingService.isPointsAssigned(false);
    setTimeout(() => {
      this.pointsAssignedSub.unsubscribe();
    }, 300);
  }
}
