import { Component, OnInit, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { BaseService } from 'src/app/core/base.service';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormGroupDirective, FormControl, NgForm, AbstractControl, ValidatorFn } from '@angular/forms';
import { AssignPoints } from './assign-points.model';
import { DataSharingService } from 'src/app/core/data-sharing.service';
import * as moment from 'moment';
import { ModalDirective } from 'angular-bootstrap-md';
import { AdalService } from 'adal-angular4';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assign-points',
  templateUrl: './assign-points.component.html',
  styleUrls: ['./assign-points.component.scss']
})

export class AssignPointsComponent implements OnInit {
  @ViewChild('frame') alert: ModalDirective;
  @ViewChild('email') span: ElementRef;

  public projects: Array<any>;
  public assignorinfo: Array<any>;
  public accomplishments: Array<any>;
  public data: any;
  public user: any;
  public points: any;
  public accomplishmentDetails: any;
  public updateUser: any;
  public pointsAssignedSub: any;
  public flag: any = false;
  public filterData: any;
  public hide: any;
  public emp_active: any = true;
  accomplishmentID;
  bonus;
  _pendingValue;

  assignForm: FormGroup;
  submitted = false;
  assignPoints: AssignPoints;
  today = new Date();
  public email: Array<any>;
  assignorbonus: any;
  updateAssignerBK: any;
  userID: any;
  assigninguserID: any;
  bonusStatus: any;
  min = 1;
  max = 500;
  balancebonus;
  bonusEntered: any;
  showError: boolean;

  constructor(public baseService: BaseService,
    public router: Router,
    private adalService: AdalService,
    public dataSharingService: DataSharingService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.getProjectList();
    // this.getAccomplishments();
    this.getAutoCompleteData();
    //console.log(this.adalService.userInfo.profile.unique_name);
    // this.baseService.get(Constants.GETFINDONEEMAIL + this.adalService.userInfo.profile.unique_name).subscribe(response => {
    //   this.assignorinfo = response;
    //   this.balancebonus = this.assignorinfo[0].bonus_kitty
    //   //console.log(this.balancebonus)
    // });

    this.baseService.get(Constants.GETALLUSERS).subscribe(response => {
    let email = this.adalService.userInfo.userName;
    if (this.data) {
      this.filterData = this.data.filter(
        element => element.email === email
        );
        // console.log(this.filterData[0].can_assign)
        if(this.authenticated){
          this.filterData[0].can_assign ? this.flag = true : this.flag = false;
          // if(this.filterData[0].can_assign){
          //   this.flag = true;
          // } else {
          //   this.flag = false;
          // }
        }
        // if (this.filterData.length && Constants.ASSIGNARRAY.includes(this.filterData[0].role.toLowerCase())) {
        //   this.flag = true;
        // } else {
        //   this.flag = false;
        // }
      }
    })
    this.assignForm = new FormGroup({
      userID: new FormControl(null, {
        validators: [Validators.required]
      }),
      accomplishmentID: new FormControl(null, {}),
      projectName: new FormControl(null, {
        validators: [Validators.required]
      }),
      points: new FormControl(null, {}),
      comments: new FormControl(null, {
        validators: [Validators.required]
      }),
      
      bonus: new FormControl(null, [Validators.min(1), Validators.max(500)]),
      // bonus: new FormControl(null, [this.bonusValidator(this.min, this.max, this.balancebonus)]),
      accomplishment: new FormControl(null, {}),
      scoreType: new FormControl(null, {
        validators: [Validators.required]
      })
    });
    this.assignForm.markAsDirty();

    this.assignForm.controls['scoreType'].valueChanges.subscribe((state: any) => {
      this.hide = state;
    });
    this.setScoreTypeValidators();
  }
  // bonusValidator(min: number, max: number, balancebonus: number): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: boolean } | null => {
  //     const balancebonus = this.assignForm.controls['balbonus'].value;
  //     if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max || control.value < balancebonus)) {
  //       return { 'bonusValue': true };
  //     }
  //     return null;
  //   };
  // }
  get authenticated(): boolean {
    return this.adalService.userInfo.authenticated;
  }

  showSuccess() {
    this.toastr.success('Success', 'You have successfully assigned points', {
      positionClass: 'toast-bottom-right'
    });
  }
  get f() { return this.assignForm.controls; }

  public getProjectList() {
    this.baseService.get(Constants.GETPROJECTS).subscribe(response => {
      this.projects = response;
    });
  }


  public getAccomplishments(user) {
    if (user) {
      this.baseService.get(Constants.GETACCOMPLISHMENTS + user.role).subscribe(response => {
        this.accomplishments = response;
        // console.log(this.accomplishments)
      });
    }

  }
  // public updateEmployeePoints(user) {
  //   if (points) {
  //     this.baseService.put(Constants.POSTPOINTS + user.role).subscribe(response => {
  //       this.accomplishments = response;
  //console.log(this.accomplishments)
  //     });
  //   }
  // }

  public getAutoCompleteData() {
    this.baseService.get(Constants.GETALLUSERS).subscribe(response => {
      // this.data = response;
      // console.log(this.data)
      let activeEmployees = response.filter(
        element => element.active !== false
        );
        this.data = activeEmployees
        // console.log(activeEmployees)
    });
  }

  public getSelectedUser(event) {
    this.baseService
      .get(Constants.GETSINGLEUSER + event.target.value)
      .subscribe(response => {
        this.user = response;
        this.getAccomplishments(this.user);
        // console.log(this.user.role)

      });

  }
  setScoreTypeValidators() {
    const accomplishmentControl = this.assignForm.get('accomplishment');
    const bonusControl = this.assignForm.get('bonus');

    this.assignForm.get('scoreType').valueChanges
      .subscribe(scoreType => {

        if (scoreType === 'Bonus') {
          bonusControl.setValidators([Validators.required, Validators.min(1), Validators.max(500)]);
          accomplishmentControl.setValidators(null);
          //console.log('Bonus')
        }

        if (scoreType === 'Accomplishments') {
          bonusControl.setValidators(null);
          accomplishmentControl.setValidators([Validators.required]);
          //console.log('Accomplishments')
        }

        bonusControl.updateValueAndValidity();
        accomplishmentControl.updateValueAndValidity();
      });
  }
  public getAccomplishmentDetails(event) {
    this.baseService.get(Constants.GETACCOMPLISHMENTDETAILS + event.target.value).subscribe(response => {
      this.accomplishmentDetails = response;
      this.assignForm.patchValue({
        points: this.accomplishmentDetails.points,
        accomplishment: this.accomplishmentDetails.name
      });
    });
  }

  onSubmit(form: NgForm) {
    //console.log(this.assignForm.value)
    // return false;
    if (this.assignForm.invalid) {
      return;
    }
    // console.log(this.hide);
    this.assignPoints = {
      accomplishment: this.hide === 'Accomplishments' ? [this.accomplishmentDetails.name, this.accomplishmentDetails.type] : [''],
      points: this.hide === 'Accomplishments' ? this.assignForm.value.points : 0,
      project_name: this.assignForm.value.projectName,
      bonus_points: this.hide === 'Bonus' ? this.assignForm.value.bonus : 0,
      employee_no: this.user.employee_no,
      assigned_to: this.user.first_name + ' ' + this.user.last_name,
      assigned_by: this.adalService.userInfo.profile.name,
      assigned_date: moment().format(), // formatDate(this.today, 'yyyy-mm-dd hh:mm:ss a', 'en-US', '+0530'),
      comment: this.assignForm.value.comments
    };
    this.baseService.post(Constants.POSTPOINTS, this.assignPoints).subscribe(response => {
      this.assignForm.reset();
      this.closeAlert();
    });
    this.updateUser = { 'total_points': this.user.total_points + this.assignPoints.points + Number(this.assignPoints.bonus_points) };
    // console.log(this.updateUser)
    this.baseService.patch(Constants.GETSINGLEUSER + this.user.id, this.updateUser).subscribe(res => {
      this.dataSharingService.isPointsAssigned(true);
    });
    // console.log(Constants.GETFINDONEEMAIL + this.adalService.userInfo.profile.unique_name)
    this.baseService.get(Constants.GETFINDONEEMAIL + this.adalService.userInfo.profile.unique_name).subscribe(response => {
      this.assigninguserID = response[0].id;
      this.updateAssignerBK = { 'bonus_kitty': response[0].bonus_kitty - this.assignPoints.bonus_points };
      // console.log(this.updateAssignerBK)
      // console.log(this.assigninguserID)
      this.baseService.patch(Constants.GETSINGLEUSER + "/" + this.assigninguserID, this.updateAssignerBK).subscribe(response => {
        // console.log(response)
      })
    });
    this.showSuccess()
  }
  changeScoreType() {
    //this.assignForm.value.bonus = null;
    this.assignForm.get('bonus').reset();
    this.assignForm.get('accomplishment').reset();
    // console.log(this)
    this.baseService.get(Constants.GETFINDONEEMAIL + this.adalService.userInfo.profile.unique_name).subscribe(response => {
      this.assignorinfo = response;
      this.assignorbonus = this.assignorinfo[0].bonus_kitty
      // console.log(this.assignorbonus)
      if (this.assignorbonus == 0) {
        this.bonusStatus = "disabled";
        // console.log(this.bonusStatus)
      }
    });
  }
  validateBonus() {
    //console.log(this.assignForm)
   // console.log(this.assignForm.get('bonus').value)
    this.baseService.get(Constants.GETFINDONEEMAIL + this.adalService.userInfo.profile.unique_name).subscribe(response => {
      this.assignorinfo = response;
      this.assignorbonus = this.assignorinfo[0].bonus_kitty
      this.bonusEntered = this.assignForm.get('bonus').value;
      //console.log(this.assignorbonus)
      //console.log(this.bonusEntered)
      // console.log(this.assignorbonus)
      if (this.bonusEntered > this.assignorbonus) {
        //this.bonusStatus = "disabled";
        //console.log("Invalid")
        this.assignForm.get("bonus").invalid
        this.showError = true
       // this.assignForm.controls['bonus'].setErrors({ 'invalid': true });
       if (this.assignForm.invalid) {
        return;
      }

      } else {
        this.assignForm.get("bonus").valid
        this.showError = false
        // this.assignForm.controls['bonus'].setErrors(null);
      }
    });

  }

  closeAlert() {
    this.user = null;
    this.accomplishmentDetails ? this.accomplishmentDetails.accomplishmentDetails = null : '';
    this.alert.hide();
  }
  
  cancelPop(){
    // console.log("sdsf")
    this.user = null;
    this.alert.hide();
    this.assignForm.reset()
  }
}
