import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Constants } from '../shared/constants';

@Injectable()
export class AuthenticationService {
  public token: string;

  constructor(private http: Http) { }

  public login(username: string, password: string) {
    return this.http.post(Constants.BASEURL +
      Constants.TOKENURL, JSON.stringify({ username: username, password: password }))
      .subscribe((response: Response) => {
        const token = response.json();
        if (token) {
          this.token = token;
          localStorage.setItem('token', token);
          return true;
        } else {
          return false;
        }
      });
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('token');
  }

}
