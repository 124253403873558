import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accomplishment-old',
  templateUrl: './accomplishment-old.component.html',
  styleUrls: ['./accomplishment-old.component.scss']
})
export class AccomplishmentOldComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
