import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {
  ngOnInit() { }
}
