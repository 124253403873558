import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { User } from '../shared/models/user';

@Injectable()
export class DataSharingService {

  public pointsAssigned = new BehaviorSubject(false);
  public topEmployees = new BehaviorSubject([]);

  constructor() { }

  public isPointsAssigned(update: boolean) {
    this.pointsAssigned.next(update);
  }

  public isPointsUpdated(topEmp: Array<User>) {
    this.topEmployees.next(topEmp);
  }

}
