import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public userList: Array<any>;
  public user: any;
  public projectList: Array<any>;
  public skillsList: Array<any>;
  public project: any;
  public show = false;
  public buttonName: any = 'Show';
  getid: string;
  branches: Array<any>;
  employmenttype: Array<any>;
  searchText = '';

  constructor(private formBuilder: FormBuilder, public baseService: BaseService,
    public router: Router) { }

  userfrmGroup: FormGroup;
  addUser: AddUsers;
  id: any;
  showDeletedMessage: boolean;
  showSavedMessage: boolean;
  selectedItems = [];
  selectedItems2 = [];
  settings = {};
  checkedItems = [];

  projectSettings = {};
  skillSettings = {};
  ngOnInit() {

    this.branches = ['AIN', 'AUK'];
    this.employmenttype = ['Permanent', 'temporary'];
    this.loadUsers();
    this.loadProjects();
    this.loadSkills();
    this.userfrmGroup = this.formBuilder.group({
      id: [null],
      index: [{ value: null, disabled: true }],
      employee_no: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      branch: [null, Validators.required],
      employment_type: [null, Validators.required],
      profile_image: [null, Validators.required],
      //project: [null, Validators.required],
      project: [[], Validators.required],
      designation: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      badges_of_honour: [],
      bonus_kitty: [],
      total_points: [],
      manager: [null, Validators.required],
      skills: [[], Validators.required],
      role: [null, Validators.required],
      created_date:  Date.now(),
      created_by: []
    });

  //   this.projectList = [
  //     { "id": 1, "itemName": "Angular" },
  //     { "id": 2, "itemName": "JavaScript" },
  //     { "id": 3, "itemName": "HTML" },
  //     { "id": 4, "itemName": "CSS" },
  //     { "id": 5, "itemName": "ReactJS" },
  //     { "id": 6, "itemName": "HTML5" }
  // ];
  this.selectedItems = [];
  this.selectedItems2 = [];
  this.checkedItems=[];
  // this.settings = {
  //     text: "Select Skills",
  //     selectAllText: 'Select All',
  //     unSelectAllText: 'UnSelect All',
  //     classes: "myclass custom-class",
  //     primaryKey: "name",
  //     labelKey: "name"
  // };

  this.projectSettings = {
    text: "Select Projects",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: "multi-select",
    primaryKey: "id",
    labelKey: "name"
  };
  this.skillSettings = {
    text: "Select Skills",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    classes: "multi-select",
    primaryKey: "id",
    labelKey: "name"
  };

  }

  onItemSelect(item: any) {
    console.log(item);
     console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
      console.log(item);
      console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
      console.log(items);
  }
  onDeSelectAll(items: any) {
      console.log(items);
  }
  get f() { return this.userfrmGroup.controls; }
  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Hide';
    } else {
      this.buttonName = 'Show';
    }
  }
  public loadProjects() {
    this.baseService.get(Constants.PROJECTS).subscribe(response => {
      this.projectList = response;
     /*  let obj = this.projectList
      let list = []
      obj.forEach(function (obj) {
        list.push(obj.name);
      });
      console.log(list);
 */
    });
  }
  public loadSkills() {
    this.baseService.get(Constants.SKILLS).subscribe(response => {
      this.skillsList = response;
    });
  }


  onSubmit(form: NgForm) {
    if (this.userfrmGroup.invalid) {
      return;
    }
    console.log(this.userfrmGroup.value);

    const getid = this.userfrmGroup.value.id;
    this.addUser = {
      employee_no: this.userfrmGroup.value.employee_no,
      first_name: this.userfrmGroup.value.first_name,
      last_name: this.userfrmGroup.value.last_name,
      branch: this.userfrmGroup.value.branch,
      employment_type: this.userfrmGroup.value.employment_type,
      profile_image: this.userfrmGroup.value.profile_image,
      project: this.userfrmGroup.value.project,
      designation: this.userfrmGroup.value.designation,
      email: this.userfrmGroup.value.email,
      badges_of_honour: this.userfrmGroup.value.badges_of_honour,
      total_points: this.userfrmGroup.value.total_points,
      bonus_kitty: this.userfrmGroup.value.bonus_kitty,
      manager: this.userfrmGroup.value.manager,
      skills: this.userfrmGroup.value.skills,
      role: this.userfrmGroup.value.role,
      created_date: this.userfrmGroup.value.created_date,
      created_by: "Admin"
    };
    if (getid == null) {
      console.log(getid);
      this.baseService.post(Constants.USERLIST, this.addUser).subscribe(response => {
        this.userfrmGroup.reset();
        this.loadUsers();
      });
    } else {
      console.log(getid);
      this.baseService.put(Constants.USERLIST + '/' + getid, this.addUser).subscribe(response => {

        this.userfrmGroup.reset();
        this.loadUsers();
      });

    }

    this.showSavedMessage = true;
    setTimeout(() => this.showSavedMessage = false, 3000);
  }

  public loadUsers() {
    this.baseService.get(Constants.USERLIST).subscribe(response => {
      this.userList = response;
    });
  }

  filterCondition(user) {
    return user.first_name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
  }

  deleteProfile(id) {
    if (confirm('Are you sure to delete this record ?')) {
      this.baseService.delete(Constants.USERLIST + '/' + id, '').subscribe(response => {
        this.loadUsers();
      });
      this.showDeletedMessage = true;
      setTimeout(() => this.showDeletedMessage = false, 3000);
    }

  }

  updateProfile(user, i) {
    this.userfrmGroup.setValue({
      index: i,
      id: user.id,
      employee_no: user.employee_no,
      first_name: user.first_name,
      last_name: user.last_name,
      branch: user.branch,
      employment_type: user.employment_type,
      profile_image: user.profile_image,
      project: user.project,
      designation: user.designation,
      email:  user.email,
      badges_of_honour: user.badges_of_honour,
      bonus_kitty:  user.bonus_kitty,
      total_points: user.total_points,
      manager: user.manager,
      skills:  user.skills,
      role: user.role,
      created_date:  Date.now(),
      created_by:  []
    });
    this.toggle();
  }
}



export interface AddUsers {
  employee_no: string;
  first_name: string;
  last_name: string;
  branch: string;
  employment_type: string;
  profile_image: string;
  project: any;
  designation: string;
  email: string;
  badges_of_honour: string;
  total_points: number;
  bonus_kitty: number;
  manager: string;
  skills: any;
  role: string;
  created_date: string;
  created_by: string;
}
