import { Component, OnInit } from 'node_modules/@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from 'node_modules/@angular/forms';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';
import { Router } from 'node_modules/@angular/router';

@Component({
  selector: 'app-accomplishment',
  templateUrl: './accomplishment.component.html',
  styleUrls: ['./accomplishment.component.scss']
})

export class AccomplishmentComponent implements OnInit {
  public accomplishmentList: Array<any>;
  public accomplishment: any;
  public show = false;
  public buttonName: any = 'Show';
  getid: string;

  searchText = '';
  constructor(private formBuilder: FormBuilder, public baseService: BaseService,
    public router: Router) { }
  addAccomplishments: FormGroup;
  addAccomp: addAccomps;
  id: any;
  showDeletedMessage: boolean;
  showSavedMessage: boolean;

  ngOnInit() {
    this.loadAccomplishment();
    this.addAccomplishments = this.formBuilder.group({
      id: [null],
      index: [{ value: null, disabled: true }],
      name: [null, Validators.required],
      type: [null, Validators.required],
      points: [null, Validators.required],
      description: [null, Validators.required],
      icon: [],
      created_date: Date.now(),
      role: [null, Validators.required]
    });
  }
  get f() { return this.addAccomplishments.controls; }

  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if (this.show) {
      this.buttonName = 'Hide';
    } else {
      this.buttonName = 'Show';
    }
  }


  onSubmit(form: NgForm) {
    if (this.addAccomplishments.invalid) {
      return;
    }
    const getid = this.addAccomplishments.value.id;
    this.addAccomp = {
      name: this.addAccomplishments.value.name,
      type: this.addAccomplishments.value.type,
      points: this.addAccomplishments.value.points,
      description: this.addAccomplishments.value.description,
      icon: this.addAccomplishments.value.icon,
      created_date: this.addAccomplishments.value.created_date,
      role: this.addAccomplishments.value.role
    };
    if (getid == null) {
      //  debugger
      this.baseService.post(Constants.GETACCOMPLISHMENTDETAILS, this.addAccomp).subscribe(response => {
        this.addAccomplishments.reset();
        this.loadAccomplishment();
      });
    } else {
      this.baseService.put(Constants.GETACCOMPLISHMENTDETAILS + '/' + getid, this.addAccomp).subscribe(response => {

        this.addAccomplishments.reset();
        this.loadAccomplishment();
      });
    }

    this.showSavedMessage = true;
    setTimeout(() => this.showSavedMessage = false, 3000);
  }

  public loadAccomplishment() {
    this.baseService.get(Constants.GETACCOMPLISHMENTDETAILS).subscribe(response => {
      this.accomplishmentList = response;
    });
  }

  deleteBadge(id) {
    if (confirm('Are you sure to delete this record ?')) {
      this.baseService.delete(Constants.GETACCOMPLISHMENTDETAILS + '/' + id, '').subscribe(response => {
        this.loadAccomplishment();
      });
      this.showDeletedMessage = true;
      setTimeout(() => this.showDeletedMessage = false, 3000);
    }

  }



  updateProfile(accomplishment, i) {
    this.addAccomplishments.setValue({
      index: i,
      id: accomplishment.id,
      name: accomplishment.name,
      type: accomplishment.type,
      points: accomplishment.points,
      description: accomplishment.description,
      icon: accomplishment.icon,
      created_date: Date.now(),
      role: accomplishment.role
    });
    this.toggle();
  }

  filterCondition(accomplishment) {
    return accomplishment.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1;
  }
}


export interface addAccomps {
  name: string;
  type: string;
  points: number;
  description: string;
  icon: string;
  created_date: string;
  role: string;
}
