import { Injectable } from '@angular/core';
import { RequestOptions, Response } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpService } from './http.service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { Constants } from 'src/app/shared/constants';

@Injectable()
export class BaseService {

  constructor(public http: HttpService,
    public errorHandler: CustomErrorHandlerService) { }

  public get(url: string) {
    url = Constants.BASEURL + url;
    return this.http.get(url).pipe(map((res: Response) => {
      return this.handleResponse(res);
    }), catchError((error: Response): any => {
      throwError(this.errorHandler.tryParseError(error));
    }));
  }

  public post(url: string, postBody: any, options?: RequestOptions) {
    url = Constants.BASEURL + url;
    if (options) {
      return this.http.post(url, postBody, options)
        .pipe(map((res: Response) => {
          return this.handleResponse(res);
        })
        , catchError((error: Response) => throwError(this.errorHandler.tryParseError(error))));
    } else {
      return this.http.post(url, postBody)
        .pipe(map((res: Response) => {
          return this.handleResponse(res);
        })
        , catchError((error: Response) => throwError(this.errorHandler.tryParseError(error))));
    }
  }

  public delete(url: string, postBody: any) {
    url = Constants.BASEURL + url;
    return this.http.delete(url).pipe(map((res: Response) => {
      return this.handleResponse(res);
    }), catchError((error: Response) => throwError(this.errorHandler.tryParseError(error))));
  }

  public put(url: string, putData) {
    url = Constants.BASEURL + url;
    return this.http.put(url, putData).pipe(map((res: Response) => {
      return this.handleResponse(res);
    }), catchError((error: Response) => throwError(this.errorHandler.tryParseError(error))));
  }

  public patch(url: string, putData) {
    url = Constants.BASEURL + url;
    return this.http.patch(url, putData).pipe(map((res: Response) => {
      return this.handleResponse(res);
    }), catchError((error: Response) => throwError(this.errorHandler.tryParseError(error))));
  }

  public upload(url: string, file: File) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('files', file, file.name);
    }
    return this.post(url, formData);
  }

  public handleResponse(res: Response) {
    const data = res.json();
    if (data.error) {
      const error: any = { error: data.error, message: data.message };
      throw new Error(this.errorHandler.parseCustomServerErrorToString(error));
    } else {
      return data;
    }
  }

}
