import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeedbackComponent } from './feedback/feedback.component';
import { RulesComponent } from './rules/rules.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { BadgesComponent } from './admin/badges/badges.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { SetupComponent } from './admin/setup/setup.component';
import { SkillsComponent } from './admin/skills/skills.component';
import { UsersComponent } from './admin/users/users.component';
import { AccomplishmentComponent } from './admin/accomplishment/accomplishment.component';
import { AdalGuard } from 'adal-angular4';
import { AccomplishmentNewComponent } from './rules/accomplishment-new/accomplishment-new.component';
import { AccomplishmentOldComponent } from './rules/accomplishment-old/accomplishment-old.component';
import { ReportsComponent } from './reports/reports.component';

const routes: Routes = [
  { path: 'leaderboard', component: LeaderboardComponent },
  { path: 'rules', redirectTo: 'rules/new' },
  { path: 'rules/old', component: AccomplishmentOldComponent }, 
  { path: 'rules/new', component: AccomplishmentNewComponent }, 
  { path: 'feedback', component: FeedbackComponent },
  { path: 'user/details', component: UserDetailsComponent },
  // { path: 'user/details/:id', component: UserDetailsComponent, canActivate: [AdalGuard]  },
  { path: 'admin/users', component: UsersComponent, canActivate: [AdalGuard] },
  { path: 'admin/accomplishment', component: AccomplishmentComponent, canActivate: [AdalGuard] },
  { path: 'admin/badges', component: BadgesComponent, canActivate: [AdalGuard] },
  { path: 'admin/projects', component: ProjectsComponent, canActivate: [AdalGuard] },
  { path: 'admin/skills', component: SkillsComponent, canActivate: [AdalGuard] },
  { path: 'admin/setup', component: SetupComponent, canActivate: [AdalGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AdalGuard] },
  { path: '*', component: LeaderboardComponent },
  { path: '**', redirectTo: '/leaderboard' },
  { path: '', component: LeaderboardComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
