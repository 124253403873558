import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AdalService, AdalGuard, AdalInterceptor } from 'adal-angular4';

import { AppComponent } from './app.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { RulesComponent } from './rules/rules.component';
import { AlertComponent } from './shared/alert/alert.component';
import { AssignPointsComponent } from './shared/assign-points/assign-points.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { ListComponent } from './shared/list/list.component';
import { TopEmployeesComponent } from './shared/top-employees/top-employees.component';
import { AppRoutingModule } from './/app-routing.module';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import {
  ModalModule, WavesModule, InputsModule, ButtonsModule,
  CarouselModule, ChartsModule, CollapseModule, DropdownModule, NavbarModule,
  PopoverModule, TooltipModule
} from 'angular-bootstrap-md';
import { UserDetailsComponent } from './user-details/user-details.component';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BadgesComponent } from './admin/badges/badges.component';
import { ProjectsComponent } from './admin/projects/projects.component';
import { NavComponent } from './shared/nav/nav.component';
import {NgPipesModule} from 'ngx-pipes';
import { SkillsComponent } from './admin/skills/skills.component';
import { SetupComponent } from 'src/app/admin/setup/setup.component';
import { DataSharingService } from 'src/app/core/data-sharing.service';
import { UsersComponent } from './admin/users/users.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AccomplishmentComponent } from './admin/accomplishment/accomplishment.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccomplishmentOldComponent } from './rules/accomplishment-old/accomplishment-old.component';
import { AccomplishmentNewComponent } from './rules/accomplishment-new/accomplishment-new.component';
import { ReportsComponent } from './reports/reports.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SearchPipe } from './filter.pipe';
import { NgxDateRangeModule } from 'ngx-daterange';




@NgModule({
  declarations: [
    AppComponent,
    FeedbackComponent,
    RulesComponent,
    AlertComponent,
    AssignPointsComponent,
    FooterComponent,
    HeaderComponent,
    ListComponent,
    TopEmployeesComponent,
    LeaderboardComponent,
    UserDetailsComponent,
    BadgesComponent,
    AccomplishmentComponent,
    ProjectsComponent,
    NavComponent,
    SkillsComponent,
    SetupComponent,
    UsersComponent,
    AccomplishmentOldComponent,
    AccomplishmentNewComponent,
    ReportsComponent,
    SearchPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MDBBootstrapModule.forRoot(),
    AppRoutingModule,
    ButtonsModule,
    CarouselModule.forRoot(),
    ChartsModule,
    CollapseModule.forRoot(),
    DropdownModule.forRoot(),
    InputsModule.forRoot(),
    ModalModule.forRoot(),
    NavbarModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    WavesModule.forRoot(),
    CoreModule,
    ReactiveFormsModule,
    NgPipesModule,
    AngularMultiSelectModule,
    Ng2SearchPipeModule,
    NgxDateRangeModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [
    DataSharingService,
    AdalService,
    AdalGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
