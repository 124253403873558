import { Injectable } from '@angular/core';
import { Headers, Http, Request, RequestOptions, Response, XHRBackend } from '@angular/http';
import { Router } from '@angular/router';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Constants } from '../shared/constants';

@Injectable()
export class HttpService extends Http {

  constructor(backend: XHRBackend,
    options: RequestOptions,
    public router: Router) {
    super(backend, options);
  }

  public request(url: string | Request, options?: RequestOptions): Observable<Response> {
    if (typeof url === 'string') {
      if (!options) {
        options = new RequestOptions({ headers: new Headers() });
      }
      this.createRequestOptions(options);
    } else {
      this.createRequestOptions(url);
    }
    return super.request(url, options).pipe(catchError(this.catchAuthError()));
  }


  public createRequestOptions(options: RequestOptions | Request) {
    const token: any = localStorage.getItem('token');
    const contentTypeHeader: string = options.headers.get('Content-Type');
    if (!contentTypeHeader) {
      options.headers.append('Content-Type', Constants.DEFAULTCONTENTTYPEHEADER);
    }
    options.headers.append('Authorization', 'Bearer ' + token);
  }

  public catchAuthError() {
    return (res: Response) => {
      if (res.status === 401 || res.status === 403) {
        localStorage.removeItem('token');
        // this.router.navigate([Constants.LOGINURL]);
      }
      return throwError(res);
    };
  }
}
