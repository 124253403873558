export class Constants {
  public static readonly BASEURL: string = 'https://gamifyapi.arrkgroup.com/api/'; //LIVE
  // public static readonly BASEURL: string = 'http://192.168.2.59:3000/api/'; //Prod
  // public static readonly BASEURL: string = 'https://gamifysit.arrkgroup.com/api/'; //SIT OLD - http://10.0.1.146:3000/api/
  // public static readonly BASEURL: string = 'http://localhost:3000/api/'; //CLUSTER
  // public static readonly BASEURL: string = 'http://127.0.0.1:3000/api/';
  public static readonly DEFAULTCONTENTTYPEHEADER: string = 'application/json';
  public static readonly AUTHENTICATEURL: string = 'authenticate';
  public static readonly TOKENURL: string = 'token';
  public static readonly LOGOUTURL: string = 'signout';
  public static readonly DEFAULTPAGESIZE: number = 100;
  public static readonly GETALLUSERS: string = 'users/';
  public static readonly USERS: string = 'users?filter[order]=total_points DESC&filter[include]=points';
  public static readonly TOPUSERS: string = 'users?filter[order]=total_points DESC&filter[limit]=3&filter[where][total_points][gt]=0';
  public static readonly PUTUSER: string = 'user/';
  // tslint:disable-next-line:max-line-length
  public static readonly SEARCHUSERS: string = 'users?filter[order]=total_points DESC&filter[where][first_name][like]=';
  public static readonly POINTSURL: string = 'points?filter[limit]=3&filter[where][employee_no]=';
  public static readonly RESETPOINTS: string = 'users/update?where=null';
  // public static readonly SETBONUSPOINTS: string = 'users/update?where={"or":[{"role":"Scrum Master"},{"role":"Business Analyst"}]}';
  public static readonly SETBONUSPOINTS: string = 'users/update?where={"or":[{"can_assign":true}]}';
  public static readonly BONUSKITTYPOINTS: string = '5000';
  public static readonly GETSINGLEUSER: string = 'users/';
  public static readonly GETFINDONE: string = 'users/findOne/?filter[employee_no]=';
  public static readonly GETFINDONEEMAIL: string = 'users?filter[where][email]=';
  public static readonly GETALLMYASSIGNEDPOINTS: string = 'points/?filter[order]=assigned_date DESC&filter[where][project_name]=';
  public static readonly GETUSERCOMMENTS: string = 'points/?filter[order]=assigned_date DESC&filter[where][employee_no]=';
  public static readonly GETPROJECTS: string = 'projects';
  public static readonly GETACCOMPLISHMENTS: string = 'accomplishments?filter[where][role][inq]=Common&filter[where][role][inq]=Enablers&filter[where][role][inq]=';
  public static readonly GETACCOMPLISHMENTDETAILS: string = 'accomplishments/';
  public static readonly POSTPOINTS: string = 'points';
  public static readonly BADGES: string = 'badges';
  public static readonly PROJECTS: string = 'projects';
  public static readonly SKILLS: string = 'skills';
  public static readonly USERLIST: string = 'users';
  public static readonly ASSIGNARRAY: Array<any> = ['tech leads', 'test leads', 'lead ba', 'scrum master', 'pm', 'Delivery Manager', 'support lead','lead'];
  public static readonly SUPERADMIN: string = 'super admin';
}
