import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';

import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';
import { CustomErrorHandlerService } from './custom-error-handler.service';
import { HttpService } from './http.service';

@NgModule({
  imports: [
    CommonModule,
    HttpModule
  ],
  declarations: [],
  providers: [
    AuthenticationService,
    BaseService,
    CustomErrorHandlerService,
    HttpService
  ]
})
export class CoreModule { }
