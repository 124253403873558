import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BaseService } from 'src/app/core/base.service';
import { Constants } from 'src/app/shared/constants';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent {

  constructor(private baseService: BaseService) { }

  public canResetPoints(): boolean {
    return moment().month() === 3 && moment().date() <= 7;
  }

  public resetPoints() {
    const resetCriteria = { 'total_points': 0, 'points': 0 };
    this.baseService.post(Constants.RESETPOINTS, resetCriteria).subscribe();
    this.baseService.post(Constants.SETBONUSPOINTS, { 'bonus_kitty': Constants.BONUSKITTYPOINTS }).subscribe();
    console.log(resetCriteria)
    
  }

}
